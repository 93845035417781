import { Atom } from ":mods";
import { IntroPageProps } from "../../model";

export function IntroPage({onConfirm}:IntroPageProps){
      return(
            <section class="flex flex-col gap-40px w-846px pt-80px">
                  <h1>Attention Museum Industry Professionals!</h1>
                  <p class="text-21px">
                  We are looking for expert speakers to participate in an a bespoke training programme that is being developed for and delivered in the Kingdom of Saudi Arabia.
                  </p>
                  <div class="w-420px mx-auto">
                  <Atom.Buttons.Button onClick={onConfirm} theme="dark" text="I would like to become an speaker (example text)"/>
                  </div>
            </section>
      )
}